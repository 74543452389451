var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "about-page" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "section",
        { staticClass: "about-page__section about-page__section--video" },
        [
          _c("video", {
            attrs: {
              autoplay: "",
              muted: "",
              loop: "",
              src: "/files/video-mainpage.mp4"
            },
            domProps: { muted: true }
          })
        ]
      )
    ]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c(
        "section",
        { staticClass: "about-page__section about-page__section--info" },
        [
          _c("div", { staticClass: "about-page__directions" }, [
            _c("h3", { staticClass: "about-page__directions-title" }, [
              _vm._v("Основные направления деятельности «НН Девелопмент»:")
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "about-page__directions-list" }, [
              _c("li", { staticClass: "about-page__directions-item" }, [
                _vm._v(
                  "Управление крупными инвестиционными проектами и портфелями."
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "about-page__directions-item" }, [
                _vm._v(
                  "Комплексное проектирование производственных и социальных объектов."
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "about-page__directions-item" }, [
                _vm._v(
                  "Управление строительством как новых, так и реконструируемых объектов."
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "about-page__targets" }, [
            _c("h4", { staticClass: "about-page__targets-title" }, [
              _vm._v("Инвестиционные проекты «Норникеля» направлены на:")
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "about-page__targets-list" }, [
              _c("li", { staticClass: "about-page__targets-item" }, [
                _c("img", {
                  attrs: { src: "/images/company/about/infrastructure.svg" }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "about-page__targets-text" }, [
                  _vm._v(
                    "\n                            реализацию новых инфраструктурных проектов\n                        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "about-page__targets-item" }, [
                _c("img", {
                  attrs: { src: "/images/company/about/coal-cart.svg" }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "about-page__targets-text" }, [
                  _vm._v(
                    "\n                            развитие минерально-сырьевого комплекса\n                        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "about-page__targets-item" }, [
                _c("img", {
                  attrs: { src: "/images/company/about/modernization.svg" }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "about-page__targets-text" }, [
                  _vm._v(
                    "\n                            модернизацию обогатительных и металлургических мощностей\n                        "
                  )
                ])
              ])
            ])
          ])
        ]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4e926d46", { render: render, staticRenderFns: staticRenderFns })
  }
}