var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "geo-tooltip-template" },
    [
      _c("button", { staticClass: "geo-tooltip-template__close" }, [
        _c(
          "svg",
          {
            staticClass: "z-icon z-fill",
            staticStyle: { width: "24px", height: "24px" },
            attrs: { version: "1.1", viewBox: "0 0 24 24" }
          },
          [
            _c("path", {
              attrs: {
                pid: "0",
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d:
                  "M18.424 6.425a.6.6 0 10-.848-.849L12 11.152 6.424 5.576a.6.6 0 10-.848.849L11.15 12l-5.575 5.576a.6.6 0 00.848.849L12 12.849l5.576 5.576a.6.6 0 00.848-.849L12.848 12l5.576-5.575z",
                fill: "#0077C8"
              }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c("span", {
        staticClass: "geo-tooltip-template__title",
        domProps: { innerHTML: _vm._s(_vm.title) }
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "geo-tooltip-template__text",
        domProps: { innerHTML: _vm._s(_vm.text) }
      }),
      _vm._v(" "),
      _c(
        _vm.link ? "a" : "button",
        {
          tag: "component",
          staticClass: "geo-tooltip-template__link",
          attrs: { href: _vm.link },
          on: { click: _vm.clickHandler }
        },
        [_vm._v("\n            Реализуемые проекты\n        ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3bc450c6", { render: render, staticRenderFns: staticRenderFns })
  }
}