import Vue from 'vue';
import geography from "./geography.vue";
import geoTooltipTemplate from "./geo-tooltip-template.vue";

const container = document.getElementById('geography');
const exists = typeof(container) != 'undefined' && container != null;

if (exists) initApp();

function initApp() {
	new Vue({
		el: '#geography',
		components: {
			geography,
			geoTooltipTemplate
		}
	});
}
