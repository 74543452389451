<template>
    <div class="project-progress">
        <h2 class="project-progress__title">Статус реализации проекта:</h2>
        <template v-for="(item, index) in progress">
            <div class="project-progress__content">
                <div class="project-progress__status">
                    <span
                        class="project-progress__tag"
                        :class="{'project-progress__tag--planning': item.status.value === 'planning'}"
                        v-html="item.status.name"
                    />
                </div>
                <div class="project-progress__preview">
                    <span class="project-progress__stage" v-html="index + 1"/>
                    <h3 class="project-progress__preview-title" v-html="item.title"/>
                    <div v-if="item.description" class="project-progress__preview-description" v-html="item.description" />
                </div>
            </div>
        </template>

    </div>
</template>

<script>
export default {
    name: "project-progress",
    props: {
        progress: {
            type: Array,
            default: () => []
        }
    }
}
</script>
