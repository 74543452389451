var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-progress" },
    [
      _c("h2", { staticClass: "project-progress__title" }, [
        _vm._v("Статус реализации проекта:")
      ]),
      _vm._v(" "),
      _vm._l(_vm.progress, function(item, index) {
        return [
          _c("div", { staticClass: "project-progress__content" }, [
            _c("div", { staticClass: "project-progress__status" }, [
              _c("span", {
                staticClass: "project-progress__tag",
                class: {
                  "project-progress__tag--planning":
                    item.status.value === "planning"
                },
                domProps: { innerHTML: _vm._s(item.status.name) }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "project-progress__preview" }, [
              _c("span", {
                staticClass: "project-progress__stage",
                domProps: { innerHTML: _vm._s(index + 1) }
              }),
              _vm._v(" "),
              _c("h3", {
                staticClass: "project-progress__preview-title",
                domProps: { innerHTML: _vm._s(item.title) }
              }),
              _vm._v(" "),
              item.description
                ? _c("div", {
                    staticClass: "project-progress__preview-description",
                    domProps: { innerHTML: _vm._s(item.description) }
                  })
                : _vm._e()
            ])
          ])
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-654dda82", { render: render, staticRenderFns: staticRenderFns })
  }
}