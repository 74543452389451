<template>
    <div class="tag-filter">
        <div class="tag-filter__list">
            <button
                class="tag-filter__item tag-filter__item--clear"
                :class="{ 'is-selected': !selected.length }"
                @click="clear"
            >
                <span class="tag-filter__item-caption">Все площадки</span>
            </button>
            <button
                class="tag-filter__item"
                :class="{ 'is-selected': selected.includes(item.id)}"
                v-for="(item, index) in data"
                :key="index"
                @click="onClick(item)"
            >
                <span class="tag-filter__item-caption" v-html="item.text"></span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'tag-filter',
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            selected: [],
        }
    },
    methods: {
        onClick (button) {
            if (!this.selected.includes(button.id)){
                this.selected.push(button.id)
            } else {
                let index = this.selected.indexOf(button.id)
                this.selected.splice(index, 1)
            }

            this.$emit('change', this.selected)
        },
        clear () {
            this.selected = []

            this.$emit('change', this.selected)
        }
    },
    mounted() {
        this.data.forEach(element => {
            if (element.selected) this.selected.push(element.id)
        })
    }
}
</script>