<template>
    <div v-if="popupContent.value !== null && popupState.value" class="object-popup">
        <div v-if="popupContent.value.image" class="object-popup__image">
            <img :src="popupContent.value.image">
        </div>
        <div class="object-popup__content">
            <span class="object-popup__title" v-if="popupContent.value.title" v-html="popupContent.value.title"/>
            <p class="object-popup__text" v-if="popupContent.value.text" v-html="popupContent.value.text"/>
            <a class="object-popup__link" :href="`#${popupContent.value.id}`" @click="linkHandler">Реализуемые
                проекты</a>
        </div>
        <button class="object-popup__close" @click="closeHandler"/>
    </div>
</template>

<script>
export default {
	name: "object-popup",
	inject: ['popupContent', 'popupHandler', 'closePopupHandler', 'popupState'],
	methods: {
		closeHandler() {
			this.popupHandler(null)
		},
		linkHandler() {
			this.closePopupHandler()
		}
	}
}
</script>
