import Vue from 'vue';
import Card from './Card.vue';

const container = document.getElementById('card');
const exists = typeof(container) != 'undefined' && container != null;

if (exists) CardComponent();

function CardComponent() {
    new Vue({
        el: '#card',
        components: {
        	Card
        }
    });
};