var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "geography-objects" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "button",
        {
          staticClass: "geography-objects__back-link",
          on: { click: _vm.goBack }
        },
        [_vm._v("Вернуться к карте России")]
      ),
      _vm._v(" "),
      _c("h2", { staticClass: "geography-objects__title" }, [
        _vm._v("Производственные площадки и объекты строительства Норильска")
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "content-container" },
        [_c("geo-object-map"), _vm._v(" "), _c("geography-object-legend")],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4e451814", { render: render, staticRenderFns: staticRenderFns })
  }
}