var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tag-filter" }, [
    _c(
      "div",
      { staticClass: "tag-filter__list" },
      [
        _c(
          "button",
          {
            staticClass: "tag-filter__item tag-filter__item--clear",
            class: { "is-selected": !_vm.selected.length },
            on: { click: _vm.clear }
          },
          [
            _c("span", { staticClass: "tag-filter__item-caption" }, [
              _vm._v("Все площадки")
            ])
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.data, function(item, index) {
          return _c(
            "button",
            {
              key: index,
              staticClass: "tag-filter__item",
              class: { "is-selected": _vm.selected.includes(item.id) },
              on: {
                click: function($event) {
                  return _vm.onClick(item)
                }
              }
            },
            [
              _c("span", {
                staticClass: "tag-filter__item-caption",
                domProps: { innerHTML: _vm._s(item.text) }
              })
            ]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-487f8630", { render: render, staticRenderFns: staticRenderFns })
  }
}