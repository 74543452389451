<template>
    <div class="geography-objects">
        <div class="container">
            <button class="geography-objects__back-link" @click="goBack">Вернуться к&nbsp;карте России</button>
            <h2 class="geography-objects__title">Производственные площадки и&nbsp;объекты строительства Норильска</h2>
        </div>
        <div class="container">
            <div class="content-container">
                <geo-object-map/>
                <geography-object-legend/>
            </div>
        </div>
    </div>
</template>

<script>
import GeoObjectMap from "./geo-object-map.vue";
import GeographyObjectLegend from "./geography-object-legend.vue";

export default {
    name: "geography-objects",
    components: {
        GeographyObjectLegend,
        GeoObjectMap
    },
    inject: ['handler'],
    provide() {
        return {
            select: this.selectObject,
            selected: this.selectedObject,
            popupHandler: this.addToPopup,
            popupContent: this.popupContent,
            closePopupHandler: this.closePopup,
            popupState: this.isPopupOpened
        }
    },
    data() {
        return {
            selectedObject: {
                value: ''
            },
            popupContent: {
                value: null
            },
            isPopupOpened: {
                value: true
            }
        }
    },
    methods: {
        selectObject(id = '') {
            this.selectedObject.value = id
        },
        goBack() {
            this.handler()
        },
        addToPopup(item) {
            this.popupContent.value = item
            this.openPopup()
        },
        openPopup() {
            this.isPopupOpened.value = true
        },
        closePopup() {
            this.isPopupOpened.value = false
        }
    }
}
</script>
