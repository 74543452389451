<template>
    <component
        :href="link"
        :class="classObject"
        @click="$emit('click')"
        :is="link ? 'a' : 'div'"
        :target="buildTarget(link)"
    >
        <div class="card__content">
            <slot />
        </div>
    </component>
</template>

<script>

export default {
    name: 'card',
    props: {
        link: String,
        fullHeight: Boolean,
        external: Boolean
    },
    methods: {
        buildTarget (href) {
            if (this.external) {
                return this.external
            }
            return /^http|^\/\//.test(href) ? '_blank' : false
        }
    },
    computed: {
        classObject () {
            return [
                'card',
                {
                    'card--link': this.link,
                    'card--full-height': this.fullHeight,
                }
            ]
        }
    }
}
</script>