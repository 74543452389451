<template>
    <div class="simple-slider">
        <div class="swiper-container simple-slider__container" ref="slider">
            <div class="swiper-wrapper">
                <slot></slot>
            </div>
        </div>
        <div class="simple-slider__controls" v-if="isControlsShown">
            <div class="simple-slider__nav">
                <button
                    v-if="showNavigation"
                    class="simple-slider__nav-btn simple-slider__nav-btn--prev"
                    ref="prev"
                >
                    <svg width="13" height="22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m11.665 20.802-10-10 10-10" stroke="#0077C8" stroke-linecap="square"/></svg>
                </button>

                <div v-if="showPagination" class="simple-slider__pagination" ref="pagination"/>

                <button
                    v-if="showNavigation"
                    class="simple-slider__nav-btn simple-slider__nav-btn--next"
                    ref="next"
                >
                    <svg width="13" height="22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m1.335.802 10 10-10 10" stroke="#0077C8" stroke-linecap="square"/></svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper.js'

export default {
    name: 'simple-slider',
    props: {
        showPagination: {
            type: Boolean,
            default: true
        },
        showNavigation: {
            type: Boolean,
            default: true
        },
        slidesPerView: {
            type: [String, Number],
            default: 2
        },
        controlsPosition: {
            type: String,
            default: 'center',
            validator: (prop) => ['left', 'center', 'right'].includes(prop)
        },
        autoHeight: Boolean
    },
    data() {
        return {
            slider: undefined
        }
    },
    methods: {
        init() {
            let options = {
                observer: true,
                observeParents: true,
                autoHeight: this.autoHeight,
                navigation: {
                    prevEl: this.$refs.prev,
                    nextEl: this.$refs.next
                },
                pagination: {
                    clickable: true,
                    el: this.$refs.pagination
                },
                slidesPerView: this.slidesPerView,
                spaceBetween: 44
            }

            if (this.slidesPerView !== 1) {
                options.breakpoints = {
                    1024: {
                        spaceBetween: 32
                    },
                    700: {
                        slidesPerView: 1
                    }
                }
            }

            this.slider = new Swiper(this.$refs.slider, options)
        }
    },
    computed: {
        isControlsShown() {
            let currentSlidesPerView = this.slidesPerView
            if (window.innerWidth <=700) currentSlidesPerView = 1
            return this.$slots.default?.filter((item) => item.tag).length > currentSlidesPerView
        }
    },
    mounted() {
        this.init()
    }
}
</script>

