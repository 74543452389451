var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.popupContent.value !== null && _vm.popupState.value
    ? _c("div", { staticClass: "object-popup" }, [
        _vm.popupContent.value.image
          ? _c("div", { staticClass: "object-popup__image" }, [
              _c("img", { attrs: { src: _vm.popupContent.value.image } })
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "object-popup__content" }, [
          _vm.popupContent.value.title
            ? _c("span", {
                staticClass: "object-popup__title",
                domProps: { innerHTML: _vm._s(_vm.popupContent.value.title) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.popupContent.value.text
            ? _c("p", {
                staticClass: "object-popup__text",
                domProps: { innerHTML: _vm._s(_vm.popupContent.value.text) }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "object-popup__link",
              attrs: { href: "#" + _vm.popupContent.value.id },
              on: { click: _vm.linkHandler }
            },
            [_vm._v("Реализуемые\n            проекты")]
          )
        ]),
        _vm._v(" "),
        _c("button", {
          staticClass: "object-popup__close",
          on: { click: _vm.closeHandler }
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3d32178a", { render: render, staticRenderFns: staticRenderFns })
  }
}