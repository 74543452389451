<template>
    <div class="geography-object-legend">
        <h3 class="geography-object-legend__title">Производственные площадки:</h3>
        <div class="row">
            <div class="col-default-6 col-tablet-12">
                <template v-for="item in geoObjectsPart1">
                    <div :class="['geography-object-legend__item',
                    `geography-object-legend__item--${item.icon}`,
                    {'geography-object-legend__item--hovered': item.id === selected.value || popupId === item.id }
                    ]"
                         @mouseover="updateObject(item.id)"
                         @mouseout="updateObject()"
                         @click="addToPopup(item)"
                         :key="item.id"
                         :id="item.id"
                    >
                        <a href="#map" class="geography-object-legend__item-title" v-html="item.title"/>
                        <template v-for="link in item.links">
                            <a class="geography-object-legend__item-link" :href="link.value" v-html="link.name"/>
                        </template>
                    </div>
                </template>
            </div>
            <div class="col-default-6 col-tablet-12">
                <template v-for="item in geoObjectsPart2">
                    <div :class="['geography-object-legend__item',
                    `geography-object-legend__item--${item.icon}`,
                    {'geography-object-legend__item--hovered': item.id === selected.value || popupId === item.id}
                    ]"
                         @mouseover="updateObject(item.id)"
                         @mouseout="updateObject()"
                         @click="addToPopup(item)"
                         :key="item.id"
                         :id="item.id"
                    >
                        <a href="#map" class="geography-object-legend__item-title" v-html="item.title"/>
                        <template v-for="link in item.links">
                            <a class="geography-object-legend__item-link" :href="link.value" v-html="link.name"/>
                        </template>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {geographyObjectsPart1, geographyObjectsPart2} from "./data";

export default {
	name: "geography-object-legend",
	inject: ['select', 'selected', 'popupHandler', 'popupContent'],
	data() {
		return {
			geoObjectsPart1: geographyObjectsPart1,
			geoObjectsPart2: geographyObjectsPart2
		}
	},
	computed: {
		popupId() {
			if (this.popupContent.value !== null) {
				return this.popupContent.value.id
			}
			return ''
		}
	},
	methods: {
		updateObject(id = '') {
			this.select(id)
		},
		addToPopup(item) {
			window.scrollTo({
				top: 300,
				behavior: 'smooth'
			})
			this.popupHandler(item)
		}
	}
}
</script>
