var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "simple-slider" }, [
    _c(
      "div",
      {
        ref: "slider",
        staticClass: "swiper-container simple-slider__container"
      },
      [_c("div", { staticClass: "swiper-wrapper" }, [_vm._t("default")], 2)]
    ),
    _vm._v(" "),
    _vm.isControlsShown
      ? _c("div", { staticClass: "simple-slider__controls" }, [
          _c("div", { staticClass: "simple-slider__nav" }, [
            _vm.showNavigation
              ? _c(
                  "button",
                  {
                    ref: "prev",
                    staticClass:
                      "simple-slider__nav-btn simple-slider__nav-btn--prev"
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "13",
                          height: "22",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "m11.665 20.802-10-10 10-10",
                            stroke: "#0077C8",
                            "stroke-linecap": "square"
                          }
                        })
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showPagination
              ? _c("div", {
                  ref: "pagination",
                  staticClass: "simple-slider__pagination"
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showNavigation
              ? _c(
                  "button",
                  {
                    ref: "next",
                    staticClass:
                      "simple-slider__nav-btn simple-slider__nav-btn--next"
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "13",
                          height: "22",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "m1.335.802 10 10-10 10",
                            stroke: "#0077C8",
                            "stroke-linecap": "square"
                          }
                        })
                      ]
                    )
                  ]
                )
              : _vm._e()
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3d016790", { render: render, staticRenderFns: staticRenderFns })
  }
}