var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "geography-object-legend" }, [
    _c("h3", { staticClass: "geography-object-legend__title" }, [
      _vm._v("Производственные площадки:")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-default-6 col-tablet-12" },
        [
          _vm._l(_vm.geoObjectsPart1, function(item) {
            return [
              _c(
                "div",
                {
                  key: item.id,
                  class: [
                    "geography-object-legend__item",
                    "geography-object-legend__item--" + item.icon,
                    {
                      "geography-object-legend__item--hovered":
                        item.id === _vm.selected.value ||
                        _vm.popupId === item.id
                    }
                  ],
                  attrs: { id: item.id },
                  on: {
                    mouseover: function($event) {
                      return _vm.updateObject(item.id)
                    },
                    mouseout: function($event) {
                      return _vm.updateObject()
                    },
                    click: function($event) {
                      return _vm.addToPopup(item)
                    }
                  }
                },
                [
                  _c("a", {
                    staticClass: "geography-object-legend__item-title",
                    attrs: { href: "#map" },
                    domProps: { innerHTML: _vm._s(item.title) }
                  }),
                  _vm._v(" "),
                  _vm._l(item.links, function(link) {
                    return [
                      _c("a", {
                        staticClass: "geography-object-legend__item-link",
                        attrs: { href: link.value },
                        domProps: { innerHTML: _vm._s(link.name) }
                      })
                    ]
                  })
                ],
                2
              )
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-default-6 col-tablet-12" },
        [
          _vm._l(_vm.geoObjectsPart2, function(item) {
            return [
              _c(
                "div",
                {
                  key: item.id,
                  class: [
                    "geography-object-legend__item",
                    "geography-object-legend__item--" + item.icon,
                    {
                      "geography-object-legend__item--hovered":
                        item.id === _vm.selected.value ||
                        _vm.popupId === item.id
                    }
                  ],
                  attrs: { id: item.id },
                  on: {
                    mouseover: function($event) {
                      return _vm.updateObject(item.id)
                    },
                    mouseout: function($event) {
                      return _vm.updateObject()
                    },
                    click: function($event) {
                      return _vm.addToPopup(item)
                    }
                  }
                },
                [
                  _c("a", {
                    staticClass: "geography-object-legend__item-title",
                    attrs: { href: "#map" },
                    domProps: { innerHTML: _vm._s(item.title) }
                  }),
                  _vm._v(" "),
                  _vm._l(item.links, function(link) {
                    return [
                      _c("a", {
                        staticClass: "geography-object-legend__item-link",
                        attrs: { href: link.value },
                        domProps: { innerHTML: _vm._s(link.name) }
                      })
                    ]
                  })
                ],
                2
              )
            ]
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-22ac6b86", { render: render, staticRenderFns: staticRenderFns })
  }
}