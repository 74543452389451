<template>
    <div class="geo-tooltip-template">
		<button class="geo-tooltip-template__close">
            <svg version="1.1" viewBox="0 0 24 24" class="z-icon z-fill" style="width: 24px; height: 24px;">
				<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.424 6.425a.6.6 0 10-.848-.849L12 11.152 6.424 5.576a.6.6 0 10-.848.849L11.15 12l-5.575 5.576a.6.6 0 00.848.849L12 12.849l5.576 5.576a.6.6 0 00.848-.849L12.848 12l5.576-5.575z" fill="#0077C8"></path>
			</svg>
		</button>
        <span class="geo-tooltip-template__title" v-html="title"/>
        <span class="geo-tooltip-template__text" v-html="text"/>
        <component
                :is="link ? 'a' : 'button'"
                class="geo-tooltip-template__link"
                :href="link"
                @click="clickHandler"
        >
            Реализуемые проекты
        </component>
    </div>
</template>

<script>
export default {
	name: "geo-tooltip-template",
	inject: ['handler'],
	props: {
		title: {
			type: String,
		},
		text: {
			type: String,
		},
		link: {
			type: String,
		},
		id: {
			type: String,
			default: ''
		}
	},
	methods: {
		clickHandler() {
			this.handler(this.id)
		}
	}
}
</script>
