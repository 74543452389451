<template>
    <div class="projects-page">
        <div class="projects-page__filter">
            <div class="projects-page__filter-title">Фильтр по&nbsp;площадкам</div>
            <tag-filter :data="filter" @change="onChangeFilter"/>
            <p class="projects-page__filter-announce">Выберите конкретную площадку или несколько</p>
        </div>
        <div class="projects-page__cards-list" ref="container">
            <results :data="filteredItems" />
        </div>
    </div>
</template>

<script>
import TagFilter from './components/TagFilter.vue';
import Results from './components/Results.vue';
import { throttle } from 'throttle-debounce'


export default {
    name: 'projects-page',
    components: {
        TagFilter,
        Results
    },
    props: {
        filter: {
            type: Array,
            default: () => []
        },
        items: {
            type: Array,
            default: () => []
        },
        itemsPerPage: {
            type: String,
            default: '6'
        }
    },
    data () {
        return {
            params: {
                tags: []
            },
            page: 1
        }
    },
    methods: {
        onChangeFilter(data) {
            this.params.tags = data

            this.page = 1
        },
        listenScroll () {
            window.addEventListener('scroll', throttle(1500, e => {
                if (!this.$refs.container) return
                const offsetTop = this.$refs.container.offsetTop
                const offsetHeight = this.$refs.container.offsetHeight
                const bottom = offsetTop + offsetHeight
                const scroll = window.pageYOffset || document.documentElement.scrollTop
                const scrolled = scroll + document.documentElement.clientHeight

                if (
                    scrolled >= (bottom - 100) &&
                    !this.isLoading &&
                    this.page < this.maxPage
                ) {
                    this.page += 1
                }
            }))
        }
    },
    computed: {
        filteredItems () {
            let list = []

            if (!this.params.tags.length) {
                list = this.items
            } else {
                list = this.items.filter(item => {
                    return this.params.tags.includes(item.tag.xmlId)
                })
            }

            return list.slice(0, this.itemsPerPage * this.page)
        },
        maxPage() {
            return Math.ceil(this.items.length / this.itemsPerPage)
        }
    },
    mounted () {
        this.listenScroll()
    }
}
</script>