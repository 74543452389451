var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "projects-result" }, [
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.data, function(item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "col-tablet-6 col-v-tablet-12",
            class: "col-default-" + _vm.colCount(index)
          },
          [_c("project-card", { attrs: { data: item } })],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e757b418", { render: render, staticRenderFns: staticRenderFns })
  }
}