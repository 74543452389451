export const geographyObjectsPart1 = [
	{
		icon: 'factory',
		id: 'object-1',
		title: 'Надеждинский металлургический завод',
		text: 'Надеждинский металлургический завод перерабатывает концентраты, полученные с&nbsp;ТОФ и&nbsp;НОФ с&nbsp;получением файнштейна и&nbsp;элементарной серы',
		image: '/images/business/projects/smelting-complex-s.jpg',
		links: [
			{
				name: 'Комплексный проект Серной программы на&nbsp;Надеждинском металлургическом заводе',
				value: '/business/projects/sulfur-program-nadezhda/'
			},
			{
				name: 'Строительство 3-го плавильного комплекса',
				value: '/business/projects/smelting-complex-nadezhda/'
			}
		]
	},
	{
		icon: 'factory',
		id: 'object-2',
		title: 'Медный завод',
		text: 'Медный завод перерабатывал весь объем медных концентратов НОФ и&nbsp;ТОФ и&nbsp;покупного сырья с&nbsp;получением катодной меди, элементарной серы и&nbsp;серной кислоты для технологических нужд Заполярного филиала.',
		image: '/images/business/projects/sulfur-program-copper-plant-s.jpg',
		links: [
			{
				name: 'Комплексный проект Серной программы на&nbsp;Медном заводе',
				value: '/business/projects/sulfur-program-copper-plant/'
			}
		]
	},
	{
		icon: 'factory-2',
		id: 'object-3',
		title: 'Талнахская обогатительная фабрика',
		text: 'Талнахская обогатительная фабрика перерабатывает богатые, медистые и&nbsp;вкрапленные руды Октябрьского и&nbsp;Талнахского месторождений с&nbsp;получением никель-пирротинового, медного концентратов и&nbsp;металлосодержащего продукта. Основные технологические операции&nbsp;&mdash; дробление, измельчение, флотация и&nbsp;сгущение.',
		image: '/images/business/projects/talnakh-concentrator-s.jpg',
		links: [
			{
				name: 'Реконструкция и&nbsp;техническое перевооружение ТОФ с&nbsp;увеличением мощности до&nbsp;18&nbsp;млн. тонн в&nbsp;год по&nbsp;сумме руд',
				value: '/business/projects/reconstruction-and-re-equipment-talnakh-concentrator/'
			},
		]
	},
	{
		icon: 'factory-2',
		id: 'object-4',
		title: 'Норильская обогатительная фабрика',
		text: 'Новый обогатительный комплекс Норильского дивизиона для переработки вкрапленных руд',
		image: '/images/business/projects/norilsk-concentrator-s.jpg',
		links: [
			{
				name: 'Строительство новой Норильской обогатительной фабрики (НОФ-2)',
				value: '/business/projects/construction-norilsk-concentrator/'
			}
		]
	}
]

export const geographyObjectsPart2 = [
	{
		icon: 'ruby',
		id: 'object-5',
		title: 'Рудник &laquo;Заполярный&raquo;',
		text: 'Один из&nbsp;старейших рудников Норильского промышленного района, ведет добычу вкрапленных сульфидных медно-никелевых руд на&nbsp;месторождении &laquo;Норильск-1&raquo;.',
		image: '/images/business/projects/zapolyarny-s.jpg',
		links: [
			{
				name: 'Комбинированная отработка оставшихся запасов вкрапленных руд месторождения &laquo;Норильск-1&raquo;',
				value: '/business/projects/combined-reserves-mining-zapolyarny/'
			}
		]
	},
	{
		icon: 'car',
		id: 'object-6',
		title: 'Рудник &laquo;Мокулаевский&raquo;',
		text: 'Строительство нового рудника по&nbsp;добыче известняка идет на&nbsp;расстоянии нескольких километров в&nbsp;северо-западном направлении от&nbsp;Талнаха. Рудник станет основной сырьевой базой известняка для &laquo;Серной программы&raquo;.',
		image: '/images/business/projects/mokulaevskoye-s.jpg',
		links: [
			{
				name: 'Месторождение &laquo;Мокулаевское&raquo;. Добыча известняка',
				value: '/business/projects/mokulaevskoye-field/'
			}
		]
	},
	{
		icon: 'plane',
		id: 'object-7',
		title: 'Аэропорт &laquo;Норильск&raquo;',
		text: 'Аэропорт Норильска&nbsp;&mdash; главная воздушная гавань севера Красноярского края, связывающая район с&nbsp;крупными города России и&nbsp;населенными пунктами севера. Аэропорт имеет статус федерального значения.',
		image: '/images/business/projects/airport-s.jpg',
		links: [
			{
				name: 'Реконструкция склада ГСМ в&nbsp;Аэропорту &laquo;Норильск&raquo;',
				value: '/business/projects/warehouse-reconstruction-norilsk-airport/'
			}
		]
	},
	{
		icon: 'electro',
		id: 'object-8',
		title: 'п.&nbsp;Тухард',
		text: 'Выступает как основная площадка для размещения резервуарного парка метанола, который является транзитным для транспортировки до&nbsp;месторождений АО&nbsp;&laquo;Норильскгазпром&raquo; (Северо-Соленинского ГКМ, Южно-Соленинского ГКМ, Мессояхского ГМ, Пеляткинского ГКМ).',
		image: '/images/business/projects/tukhard-s.jpg',
		links: [
			{
				name: 'Строительство и&nbsp;реконструкция инфраструктуры поселка Тухард',
				value: '/business/projects/building-and-reconstruction-tukhard/'
			}
		]
	},
	{
		icon: 'electro',
		id: 'object-9',
		title: 'Энергетическая инфраструктура Норильского промышленного района',
		text: 'Строительство и&nbsp;ввод в&nbsp;промышленную эксплуатацию производственного комплекса зданий и&nbsp;сооружений энергоблоков &#8470;&nbsp;7&nbsp;и &#8470;&nbsp;8&nbsp;Норильской теплоэлектроцентрали &#8470;&nbsp;3&nbsp;с установкой основного и&nbsp;вспомогательного оборудования, строительство воздушных линий электропередачи, поддержание сетей тепловодоснабжения.',
		image: '/images/business/projects/energy-infrastructure-s.jpg',
		links: [
			{
				name: 'Подробнее',
				value: '/business/projects/energy-infrastructure/'
			}
		]
	},
]
