<template>
    <div class="geography">
        <div class="geography__map-layout">
            <template v-if="selected === 'norilsk'">
                <geography-objects/>
            </template>
            <template v-else>
                <div class="container">
                    <geography-map/>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import GeographyMap from "./geography-map.vue";
import GeoTooltipTemplate from "./geo-tooltip-template.vue";
import GeographyObjects from "./geography-objects.vue";

export default {
    name: "geography",
    components: {
        GeographyObjects,
        GeographyMap,
        GeoTooltipTemplate
    },
    provide() {
        return {
            handler: this.changeSelected
        }
    },
    data() {
        return {
            selected: ''
        }
    },
    methods: {
        changeSelected(id) {
            this.selected = id
        }
    }
}
</script>
