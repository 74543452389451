<template>
    <div class="about-page">
        <div class="container">
            <section class="about-page__section about-page__section--video">
                <video autoplay muted loop src="/files/video-mainpage.mp4"></video>
            </section>
        </div>

        <div class="container">
            <section class="about-page__section about-page__section--info">
                <div class="about-page__directions">
                    <h3 class="about-page__directions-title">Основные направления деятельности «НН&nbsp;Девелопмент»:</h3>

                    <ul class="about-page__directions-list">
                        <li class="about-page__directions-item">Управление крупными инвестиционными проектами и портфелями.</li>
                        <li class="about-page__directions-item">Комплексное проектирование производственных и социальных объектов.</li>
                        <li class="about-page__directions-item">Управление строительством как новых, так и реконструируемых объектов.</li>
                    </ul>
                </div>

                <div class="about-page__targets">
                    <h4 class="about-page__targets-title">Инвестиционные проекты «Норникеля»&nbsp;направлены&nbsp;на:</h4>

                    <ul class="about-page__targets-list">
                        <li class="about-page__targets-item">
                            <img src="/images/company/about/infrastructure.svg">
                            <p class="about-page__targets-text">
                                реализацию новых инфраструктурных проектов
                            </p>
                        </li>
                        <li class="about-page__targets-item">
                            <img src="/images/company/about/coal-cart.svg">
                            <p class="about-page__targets-text">
                                развитие минерально-сырьевого комплекса
                            </p>
                        </li>
                        <li class="about-page__targets-item">
                            <img src="/images/company/about/modernization.svg">
                            <p class="about-page__targets-text">
                                модернизацию обогатительных и металлургических мощностей
                            </p>
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Card from '../card/Card.vue';

export default {
    name: 'about-page',
    components: {
        Card
    }
};
</script>
