import Vue from 'vue';
import SimpleSlider from "./SimpleSlider.vue";
import Slide from "./Slide.vue";

const container = document.getElementById('simple-slider');
const exists = typeof(container) != 'undefined' && container != null;

if (exists) ProjectsPageApp();

function ProjectsPageApp() {
    new Vue({
        el: '#simple-slider',
        components: {
            SimpleSlider,
            Slide
        }
    });
};
