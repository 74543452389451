<template>
    <div class="projects-result">
        <div class="row">
            <div
                v-for="(item, index) in data" :key="index"
                :class="`col-default-${colCount(index)}`"
                class="col-tablet-6 col-v-tablet-12"
            >
                <project-card :data="item" />
            </div>
        </div>
    </div>
</template>
<script>
import ProjectCard from './ProjectCard.vue';

export default {
    name: 'projects-result',
    components: {
        ProjectCard
    },
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        colCount(index) {
            if (index === 0 ) return '7' //первый элемент всегда на 7 колонок

            return (Math.ceil(index / 2) % 2) > 0 ? '5' : '7' //если следующая пара элементов не четная, то карточка 5 колонок, иначе - 7
        }
    }
}
</script>