import Vue from 'vue';
import ProjectProgress from "./ProjectProgress.vue"

const container = document.getElementById('project-progress');
const exists = typeof(container) != 'undefined' && container != null;

if (exists) ProjectsProgressApp();

function ProjectsProgressApp() {
    new Vue({
        el: '#project-progress',
        components: {
            ProjectProgress
        }
    });
};
