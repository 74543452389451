var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.link ? "a" : "div",
    {
      tag: "component",
      class: _vm.classObject,
      attrs: { href: _vm.link, target: _vm.buildTarget(_vm.link) },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [_c("div", { staticClass: "card__content" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3ad07a56", { render: render, staticRenderFns: staticRenderFns })
  }
}