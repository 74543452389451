import Vue from 'vue';
import AboutPage from './AboutPage.vue';

const container = document.getElementById('about-page');
const exists = typeof(container) != 'undefined' && container != null;

if (exists) AboutPageApp();

function AboutPageApp() {
    new Vue({
        el: '#about-page',
        components: {
            AboutPage
        }
    });
};