var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "projects-page" }, [
    _c(
      "div",
      { staticClass: "projects-page__filter" },
      [
        _c("div", { staticClass: "projects-page__filter-title" }, [
          _vm._v("Фильтр по площадкам")
        ]),
        _vm._v(" "),
        _c("tag-filter", {
          attrs: { data: _vm.filter },
          on: { change: _vm.onChangeFilter }
        }),
        _vm._v(" "),
        _c("p", { staticClass: "projects-page__filter-announce" }, [
          _vm._v("Выберите конкретную площадку или несколько")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { ref: "container", staticClass: "projects-page__cards-list" },
      [_c("results", { attrs: { data: _vm.filteredItems } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-494e13a0", { render: render, staticRenderFns: staticRenderFns })
  }
}